import React from 'react';
import { Helmet } from 'react-helmet-async';
import PayPrograms from '../../constants/payPrograms';
import ProgramPropType from '../../prop-types/program';

const DESCRIPTION = 'Join GasBuddy and get the best gas card. Save on gas and groceries at your favorite gas station. Collect fuel rewards with loyalty discounts.';
const THUMBNAIL = 'https://images.gasbuddy.com/images/websites/gasbuddy/apps/cellphone-app.png';

export default function DefaultHelmet({ program }) {
  let TITLE;

  switch (program) {
    case PayPrograms.Plus:
      TITLE = 'Pay with GasBuddy Plus for Big Fuel Discounts - Save More at the Pump';
      break;
    case PayPrograms.Premium:
      TITLE = 'GasBuddy Premium - Best Roadside Assistance & Even More Fuel Savings';
      break;
    default:
      TITLE = 'Pay with GasBuddy – The Gas Card Accepted Nationwide';
  }

  return (
    <Helmet>
      <title>{TITLE}</title>
      <meta name="description" content={DESCRIPTION} />
      <meta name="og:type" content="website" />
      <meta name="og:title" content={TITLE} />
      <meta name="og:image" content={THUMBNAIL} />
      <meta name="og:description" content={DESCRIPTION} />
      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:image" content={THUMBNAIL} />
      <meta name="twitter:description" content={DESCRIPTION} />
      <meta name="google-site-verification" content="zXhVMaZ-JnjK_v5xNuzXXzO99G76HlIJhiyP31rNWsQ" />
      <meta name="facebook-domain-verification" content="9i95xs7r3tr0pkido9vsncfgkqyipu" />
      <meta name="apple-itunes-app" content="app-id=406719683, app-argument=https://itunes.apple.com/us/app/gasbuddy-find-cheap-gas-prices/id406719683?mt=8&uo=4" />
      <link rel="shortcut icon" id="favicon" type="image/x-icon" href="https://static.gasbuddy.com/web/favicon.ico" />
      <link rel="apple-touch-icon" sizes="152x152" href="https://images.gasbuddy.com/images/websites/gasbuddy/apple-touch-icon.png" />
      <link rel="alternate" href="android-app://gbis.gbandroid/gasbuddy/" />
      <script type="text/javascript">
        {'ggv2id = \'bfce2827\';'}
      </script>
      <script async defer src="https://js.gumgum.com/services.js" type="text/javascript" />
    </Helmet>
  );
}

DefaultHelmet.propTypes = {
  program: ProgramPropType,
};

DefaultHelmet.defaultProps = {
  program: PayPrograms.Free,
};
